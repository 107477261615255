@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";
@import "./@vex/styles/Bootstrapgrid";

@import "./@vex/styles/partials/plugins/_quill.scss";
@import "../node_modules/quill/dist/quill.snow.css";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
 :root {
	--color-primary: 27,188,155;
	// --color-primary: red//#1abc9c
	// --color-primary: blue;
	// --color-primary-contrast: white;

	--color-accent: 249, 160, 27;
	// --color-accent-contrast: black;

	--color-warn: 240, 83, 45;
	// --color-warn-contrast: black;
}

.w-80-per {
	width: 80%;
}

.box-shadow-5 {
	box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
}

.minimized {
	.local-wrapper {
		visibility: hidden;
		z-index: 0;
	}
}

.simplebar-mask {
	direction: inherit;
	position: absolute;
	overflow: hidden;
	padding: 0;
	margin: 0;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	width: 105% !important;
	height: auto !important;
	z-index: 0;
	/* font-size: small; */
}

.px-cht-btm-act {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
}
.px-cht-btm-act form {
	flex: 1;
	width: 100%;
}

@media (max-width: 767px) {
	.px-cht-btm-act .fab-container {
		position: unset !important;
	}
	.px-cht-btm-act form {
		margin-left: 0 !important;
		display: block;
	}
	.px-cht-btm-act form > div {
		margin-left: 0 !important;
	}
	.px-cht-btm-act {
		justify-content: center;
		flex-direction: column-reverse;
	}
	.px-cht-btm-act button {
		cursor: pointer;
	}
}
.px-linkify {
	color: #810054;
	font-weight: 500;
	text-decoration: underline;
}
@media (max-width: 375px) {
	span,
	p {
		font-size: 10px !important;
	}
	.p-4 {
		padding: 0.5rem;
	}
}
.px-linkify {
	color: #810054;
	font-weight: 500;
	text-decoration: underline;
}

@media (min-width: 320px) and (max-width: 969px) {
	.px-msg-blk,
	.px-direct-link-content {
		position: absolute !important;
		left: 0;
		top: 0;
		margin: 0 !important;
		width: 100% !important;
		height: 100% !important;
	}
	.px-cht-mbl-list-drawer,
	.px-direct-link-drawer {
		max-width: 100% !important;
		width: 100% !important;
	}
	.px-appointment-detail-drawer {
		width: 100% !important;
	}
}
.dark-shadow {
	box-shadow: 0 0 29px #00000075 !important;
}
.px-cht-txt {
	display: flex !important;
	justify-content: space-between !important;
	width: 100% !important;
}

